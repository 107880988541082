import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type VoidFn = () => void;

export const useDebouncedState = <s>(
  aanvanklike staat: S | (() => S),
  vertraging: nommer,
): [S, versending<setstateaction<s>>] => {
  const time-out = useRef<number>();

  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(initialState);

  // Debounced setState method.
  const setState = useCallback((value: S | ((prevState: S) => S)): void => {
    // If there's a current timeout then cancel it.
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    // Create our next timeout to set the next value.
    timeout.current = setTimeout((): void => {
      // Save the actual value to state.
      setDebouncedValue(value);

      // Unset the timeout so we don't clear an already finished timeout.
      timeout.current = undefined;
    }, delay) as any;
  }, []);

  // If the component unmounts and there's still a timer running, clear it.
  useEffect(
    (): VoidFn => (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  return [debouncedValue, setState];
};
</number></setstateaction<s></s>
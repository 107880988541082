import { find, noop, reduce } from 'lodash';
import { Component, ReactNode, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type ContainerQuerySize = {
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
};

type PropSizes<t> =
  | { [size: string]: ContainerQuerySize }
  | [ContainerQuerySize, T][];

export interface ContainerQueryProps<t> {
  children: (sizes: (T | string)[], rect?: ClientRect) => ReactNode;
  onResize?: (
    sizes: (string | T)[],
    entry: ResizeObserverEntry,
    previous: DOMRectReadOnly,
  ) => void;
  sizes?: PropSizes<t>;
  teiken: RefObject<htmlelement>;
}

tipe rekwisiete<t> = ContainerQueryProps<t> & typeof defaultProps;

tipe Staat<t> = {
  entries?: ResizeObserverEntry[];
  previousRect?: DOMRectReadOnly;
  sizes?: (string | T)[];
  rect?: ClientRect;
};

const defaultProps = Object.freeze({
  onResize: noop,
  sizes: {},
});

const initialState = Object.freeze({
  previousRect: undefined,
  sizes: [],
  rect: undefined,
});

export default class ContainerQuery<t> brei komponent uit<props<t>Staat<t>> {
  static defaultProps = defaultProps;
  state = initialState;

  private _observer: ResizeObserver | null = null;

  public componentDidMount() {
    this._createObserver();
  }

  public componentWillUnmount() {
    this._disconnect();
  }

  public render() {
    const { children } = this.props;
    return children(this.state.sizes, this.state.rect);
  }

  private _createObserver() {
    this._disconnect();
    const { current } = this.props.target;
    if (current) {
      const obs = new ResizeObserver(this._handleResize);
      const rect = current.getBoundingClientRect();
      this.setState({
        rect,
        sizes: this._mapRectToSize(rect),
      });
      obs.observe(current);
      this._observer = obs;
    }
  }

  private _disconnect() {
    const obs = this._observer;
    if (obs) obs.disconnect();
  }

  private _mapRectToSize({
    height,
    width,
  }: {
    height: number;
    width: number;
  }): (string | T)[] {
    const def = {
      maxHeight: Infinity,
      maxWidth: Infinity,
      minHeight: 0,
      minWidth: 0,
    };
    return reduce(
      this.props.sizes,
      (acc, entry, name) => {
        let compVal;
        let retVal;
        if (Array.isArray(entry)) {
          compVal = {
            ...def,
            ...entry[0],
          };
          retVal = entry[1];
        } else {
          compVal = entry;
          retVal = name;
        }
        if (
          width >= compVal.minWidth &&
          width <= compVal.maxWidth &&
          height >= compVal.minHeight &&
          height <= compVal.maxHeight
        ) {
          acc.push(retVal);
        }
        return acc;
      },
      [] as (string | T)[],
    );
  }

  private _handleResize = (entries: ResizeObserverEntry[]) => {
    const { current } = this.props.target;
    if (current) {
      const entry = find(entries, e => e.target === current);
      if (entry) {
        const sizes = this._mapRectToSize(entry.contentRect);
        this.props.onResize(
          sizes,
          entry,
          this.state.previousRect || entry.contentRect,
        );
        this.setState({
          sizes,
          previousRect: entry.contentRect as DOMRectReadOnly,
        });
      }
    }
  };
}
</t></props<t></t></t></t></t></htmlelement></t></t></t>
import { ButtonLabelProps } from './button-label-props';
import { ButtonText } from '../typography';
import React from 'react';
import styled from 'styled-components';

const ButtonLabelComponent = React.forwardRef<any, ButtonLabelProps="">(
  (props, ref): React.ReactElement => {
    const { color, variant, ...rest } = props;
    return <buttontext {...rest}="" ref="{ref}"></buttontext>;
  },
);

ButtonLabelComponent.displayName = 'ButtonLabel';

uitvoer const ButtonLabel = gestileer (ButtonLabelComponent)'
  vertoon: buig;
  oorgang: kleur 120ms;
`;
</any,>
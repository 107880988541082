import React, { ReactNode, SFC } from 'react';
import Ink from '../Ink';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background'),
    cursor: 'pointer',
    position: 'relative',
  },
}));

export type ListPickerOptionType<v =="" {}=""> = {
  key: string;
  label: ReactNode;
  value: V;
};

export interface ListPickerOptionProps<v> brei ListPickerOptionType uit<v> {
  className?: string;
  inkClassName?: string;
  onClick?: (value: V) => void;
  labelClassName?: string;
}

type Props<v> = MetStyles<listpickeroptionprops<v>, tipe style>;

const ListPickerOption: SFC<props<any>> = ({
  Klasse
  klas Naam,
  onClick,
  Etiket
  labelClassName,
}) => (
  <listitem className="{cx(classes.root," className)}="" onClick="{onClick}">
    <div className="{cx(labelClassName)}">{label}</div>
    <ink></ink>
  </listitem>
);

standaard uitvoer metStyles (style) (ListPickerOption);
</props<any></listpickeroptionprops<v></v></v></v></v>
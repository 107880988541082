import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

import {
  Blockquote,
  FormatBold,
  FormatItalic,
  FormatOrderedList,
  FormatStrikeThrough,
  FormatUnderline,
  FormatUnorderedList,
} from '@robotsnacks/icons';

export type Format =
  | 'blockquote'
  | 'bold'
  | 'italic'
  | 'strike-through'
  | 'underline'
  | 'ordered-list'
  | 'unordered-list';

const iconMap: any = {
  'ordered-list': <formatorderedlist></formatorderedlist>,
  Slaan: <formatstrikethrough></formatstrikethrough>,
  'ongeordende lys': <formatunorderedlist></formatunorderedlist>,
  blokraai: <blockquote></blockquote>,
  Vet: <formatbold></formatbold>,
  kursief: <formatitalic></formatitalic>,
  Onderlyn: <formatunderline></formatunderline>,
};

export type FormatButtonProps = {
  format: Format;
  quill: Quill;
};

const FormatButton: SFC<formatbuttonprops> = ({ format, quill }) => {
  const selection = quill.getSelection();
  format = mapToQuillFormat(format) as any;
  return (
    <toolbarbutton active="{!!selection" &&="" quill.getFormat()[format]="==" true}="" onMouseDown="{e" ==""> {
        e.preventDefault();
        quill.format(format, !quill.getFormat()[format], 'user');
      }}
    >
      {iconMap[format]}
    </toolbarbutton>
  );
};

function mapToQuillFormat(format: Format): string {
  switch (format) {
    case 'strike-through':
      return 'strike';
    default:
      return format;
  }
}

export default FormatButton;
</formatbuttonprops>
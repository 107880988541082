import { filter, head, keys, map, reduce } from 'lodash';
import { Quill } from 'quill';
import React from 'react';
import ToolbarSeparator from '../ToolbarSeparator';
import AlignFlyout from './AlignmentFlyout';
import FontFlyout from './FontFlyout';
import FormatButton from './FormatButton';
import LinkButton from './LinkButton';
import ListFormatButton from './ListFormatButton';
import RedoButton from './RedoButton';
import UndoButton from './UndoButton';

import { ToolbarInterface } from './ToolbarInterface';

export type EditorItemKey =
  | 'add-comment'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align'
  | 'attachment'
  | 'blockquote'
  | 'bold'
  | 'clear-format'
  | 'code'
  | 'dedent'
  | 'embed'
  | 'fill-color'
  | 'font-select'
  | 'font-size'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'highlight-color'
  | 'image'
  | 'indent'
  | 'italic'
  | 'link'
  | 'ordered-list'
  | 'p'
  | 'redo'
  | 'separator'
  | 'strike-through'
  | 'text-color'
  | 'underline'
  | 'undo'
  | 'unordered-list'
  | 'video';

export type EditorItem = EditorItemKey | Partial<{ [K in EditorItemKey]: any }>;

const createEditorItem = (
  item: EditorItem,
  index: number,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const key: string | EditorItemKey =
    typeof item === 'string' ? item : (head(keys(item)) as EditorItemKey);

  // FIXME: fix any cast.
  const options = typeof item === 'string' ? null : (item as any)[key];

  switch (key) {
    case 'bold':
    case 'italic':
    case 'underline':
    case 'strike-through':
    case 'blockquote':
      return <formatbutton key="{key}" format="{key}" quill="{quill}"></formatbutton>;

saak 'skeier':
      Terugkeer <toolbarseparator key="{index}"></toolbarseparator>;

saak 'align':
      Terugkeer <alignflyout key="{key}" quill="{quill}"></alignflyout>;

saak 'ongedaan maak':
      Terugkeer <undobutton key="{key}" quill="{quill}"></undobutton>;

saak 'oordoen':
      Terugkeer <redobutton key="{key}" quill="{quill}"></redobutton>;

geval 'font-select':
      Terugkeer <fontflyout key="{key}" quill="{quill}" options="{options}"></fontflyout>;

saak 'skakel':
      Terugkeer <linkbutton key="{key}" quill="{quill}" toolbar="{toolbar}"></linkbutton>;

saak 'geordende lys':
    saak 'ongeordende lys':
      Terugkeer <listformatbutton key="{key}" format="{key}" quill="{quill}"></listformatbutton>;

Geval 'Voeg kommentaar by':
    saak 'aanhegting':
    saak 'duidelike formaat':
    geval 'kode':
    saak 'dedent':
    saak 'inbed':
    geval 'vulkleur':
    geval 'lettergrootte':
    geval 'hoogtepunt-kleur':
    saak 'beeld':
    saak 'inspringing':
    geval 'tekskleur':
    Geval 'Video':

saak 'align-center':
    saak 'align-justify':
    saak 'align-left':
    saak 'align-right':

Saak 'H1':
    Saak 'H2':
    Saak 'H3':
    Saak 'H4':
    Saak 'H5':
    Saak 'H6':

Saak 'P':
      gooi nuwe fout ('Nie geïmplementeer nie.');
  }
};

uitvoer standaard (
  items: Skikking<editoritem |="" EditorItem[]="">,
  quill: Quill,
  toolbar?: ToolbarInterface,
) => {
  const reduced = reduce(
    items,
    (acc: EditorItem[], item: EditorItem | EditorItem[]) => {
      if (Array.isArray(item)) {
        return acc.concat(['separator', ...item, 'separator']);
      }
      return acc.concat([item]);
    },
    [] as any,
  );

  const filtered = filter(reduced, (item, i: any, array) => {
    // Don't show separator as first/last items.
    if (i === 0 || i === array.length - 1) return item !== 'separator';
    // Don't show consecutive separators.
    if (item === 'separator' && array[i - 1] === 'separator') return false;
    return true;
  });

  return map(filtered, (item, i) => createEditorItem(item, i, quill, toolbar));
};
</editoritem>
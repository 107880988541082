import React, { HTMLAttributes, SFC } from 'react';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'inner'>(theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'inline-block',
    lineHeight: 2,
    margin: '1%',
    padding: '0',
    position: 'relative',
    width: '23%',
  },
  inner: {
    padding: '0 0.5rem',
  },
}));

export interface GridListItemProps extends HTMLAttributes<htmldivelement> {}

tipe rekwisiete = Metstyle<gridlistitemprops, typeof="" styles="">;

const GridListItem: SFC<props> = ({
  Kinders
  Klasse
  klas Naam,
  Tema
  ... rekwisiete
}) => (
  <listitem className="{cx(classes.root," className)}="" {...props}="">
    <div className="{cx(classes.inner)}">{kinders}</div>
  </listitem>
);

standaard uitvoer metStyles (style) (GridListItem);
</props></gridlistitemprops,></htmldivelement>
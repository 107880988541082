// TODO: remove dep on immutable... can we use immer? The lib is much smaller.

import { List, Map, Record } from 'immutable';
import { identity, isInteger, max, uniqBy } from 'lodash';

type GridArea = {
  column: number;
  height: number;
  row: number;
  width: number;
};

const DEFAULT_ENGINE_RECORD = {
  flow: 'down' as 'down' | 'right',
  items: Map() as Map<string, GridEngineItem="">,
  matriks: Lys () as lys<list<gridengineitem |="" null="">>,
};

const DEFAULT_ITEM_RECORD = {
  column: 0,
  height: 0,
  id: '',
  row: 0,
  width: 0,
};

const shiftIntegerInvariant = (n: number) => {
  if (n < 0 || !isInteger(n)) {
    throw new RangeError('Items may only be shifted by positive integers.');
  }
};

/**
 *
 */
export class GridEngineItem extends Record(
  DEFAULT_ITEM_RECORD,
  'GridEngineItem',
) {
  decrementRow(n: number = 1) {
    return this.set('row', this.row - n);
  }

  decrementColumn(n: number = 1) {
    return this.set('column', this.column - n);
  }

  incrementRow(n: number = 1) {
    return this.set('row', this.row + n);
  }

  incrementColumn(n: number = 1) {
    return this.set('column', this.column + n);
  }
}

/**
 *
 */
export default class GridEngine extends Record(
  DEFAULT_ENGINE_RECORD,
  'GridEngine',
) {
  static create(): GridEngine {
    return new GridEngine();
  }

  static fromItems(
    items: List<typeof DEFAULT_ITEM_RECORD=""> | typeof DEFAULT_ITEM_RECORD[],
    flow?: 'down' | 'right',
  ): GridEngine {
    const arr = List.isList(items) ? items.toArray() : items;
    return new GridEngine({
      flow,
    }).placeItems(...arr);
  }

  /**
   * Returns the number of rows in the layout.
   */
  get rows() {
    if (this.flow === 'right') return this._countColumns();
    return this.matrix.size;
  }

  /**
   * Returns the number of columns in the layout.
   */
  get columns() {
    if (this.flow === 'right') return this.matrix.size;
    return this._countColumns();
  }

  /**
   * Method alias for #insertItem().
   */
  insertItems(...inserts: typeof DEFAULT_ITEM_RECORD[]): this {
    return this.insertItem(...inserts);
  }

  /**
   * Inserts a new item into the layout.
   * @param params Item position and size.
   */
  insertItem(...inserts: typeof DEFAULT_ITEM_RECORD[]): this {
    let layout = this;
    inserts.forEach(
      i =>
        (layout = layout._insertItem(
          this._transposeItem(new GridEngineItem(i)),
        )),
    );
    return layout;
  }

  placeItems(...insert: typeof DEFAULT_ITEM_RECORD[]): this {
    return this.placeItem(...insert);
  }

  placeItem(...inserts: typeof DEFAULT_ITEM_RECORD[]): this {
    let layout = this;
    inserts.forEach(i => {
      const item = this._transposeItem(new GridEngineItem(i));
      layout = layout
        ._insertItemInLayout(item)
        .set('items', layout.items.set(item.id, item));
    });
    return layout;
  }

  sortedItems(): List<gridengineitem> {
    return this.items
      .map(i => this._transposeItem(i))
      .toList()
      .sortBy(item => item.row)
      .sortBy(item => item.column);
  }

  /**
   * Shifts a item down by `n` positions. Will also shift the items beneath it
   * to make room for the moved item.
   * @param id The ID of the item to shift.
   * @param n The number of positions to shift it.
   */
  shiftDown(id: string, n: number = 1): this {
    shiftIntegerInvariant(n);
    let layout = this;
    const item = layout._getItem(id).incrementRow(n);
    layout = layout.removeItem(id)._insertItem(item);
    this._getItemsBelow(id)
      .reverse()
      .forEach(t => {
        layout = layout.removeItem(t.id)._insertItem(t.incrementRow(n));
      });
    return layout;
  }

  /**
   * Shifts a item up by `n` positions. Will also shift the items beneath it up
   * (if they can).
   * @param id The ID of the item to shift.
   * @param n The number of positions to shift it.
   */
  shiftUp(id: string, n: number = 1): this {
    shiftIntegerInvariant(n);
    const item = this._getItem(id);
    return this.removeItem(id)._insertItem(
      item.decrementRow(Math.min(n, this._getMaxMovesUp(item))),
    );
  }

  shiftLeft(id: string, n: number = 1): this {
    shiftIntegerInvariant(n);
    const item = this._getItem(id);
    return this.removeItem(id)._insertItem(
      item.decrementColumn(Math.min(n, this._getMaxMovesLeft(item))),
    );
  }

  compact() {
    let layout = this;
    const rows = this.matrix.size;
    const cols = this._countColumns();

    // Retrieve all items in the grid in an ordered top-left to bottom-right
    // list.
    const items = this._getItemsInArea({
      column: 0,
      height: rows,
      row: 0,
      width: cols,
    });

    if (this.flow === 'down') {
      items.forEach(item => {
        layout = layout.shiftUp(item.id, rows);
      });
    } else if (this.flow === 'right') {
      items.forEach(item => {
        layout = layout.shiftUp(item.id, cols);
      });
    }

    return layout;
  }

  /**
   * Retrieves a item by its ID.
   * @param id The ID of the item to retrieve.
   */
  public getItem(id: string): GridEngineItem | null;
  public getItem(row: number, column: number): GridEngineItem | null;
  public getItem(id: number | string, column?: number): GridEngineItem | null {
    if (typeof id === 'number' && typeof column === 'number') {
      const row = this.matrix.get(id);
      if (!row) return null;
      return row.get(column) || null;
    }
    const item = this._getItem(id as string);
    if (item) return this._transposeItem(item);
    return null;
  }

  /**
   *
   */
  public getAllItems(): Map<string, GridEngineItem=""> {
    return this.items.map(i => this._transposeItem(i));
  }

  /**
   * Removes a item by its ID.
   * @param id The ID of the item to remove.
   */
  public removeItem(id: string): this {
    const item = this._getItem(id);
    return this._removeItemFromLayout(item).set(
      'items',
      this.items.delete(item.id),
    );
  }

  /**
   *
   * @param area
   */
  public getItemsInArea(area: GridArea): List<gridengineitem> {
    return this._getItemsInArea(area).map(this._transposeItem.bind(this));
  }

  /**
   * Returns a prettified string of the matrix. (For testing and development
   * purposes mostly.)
   */
  public pretty() {
    // const layout = this.flow === 'down' ? this.matrix : this._transposeMatrix();
    return this.matrix
      .map(row => (row ? row.map(t => (t ? t.id : ' ')).join(', ') : '----'))
      .join('\n');
  }

  /**
   * Retrieves a list of items beneath the target item.
   * @param id The ID of the target item.
   */
  public getItemsBelow(id: string) {
    return this._getItemsBelow(id).map(this._transposeItem.bind(this));
  }

  /**
   * Retrieves a list of items above the target item.
   * @param id The ID of the target item.
   */
  public getItemsAbove(id: string) {
    return this._getItemsAbove(id).map(this._transposeItem.bind(this));
  }

  public trim(): this {
    let matrix = this.matrix;
    // Remove empty rows at the end.
    for (let i = matrix.size - 1; i >= 0; i--) {
      const row = matrix.get(i);
      if (row) {
        const c = row.filter(identity);
        if (c.size === 0) {
          matrix = matrix.delete(i);
        } else {
          break;
        }
      }
    }
    // Trim empty columns.
    for (let i = 0; i < matrix.size; i++) {
      const row = matrix.get(i);
      if (row) {
        matrix = matrix.set(
          i,
          row
            .reverse()
            .skipUntil(c => !!c)
            .reverse(),
        );
      }
    }
    return this.set('matrix', matrix);
  }

  /* ---------------------------------------------------------------------------
   * Private Methods
   * ------------------------------------------------------------------------ */

  /**
   *
   * @param item
   */
  private _getMaxMovesUp(item: GridEngineItem) {
    let r = item.row;
    let n = 0;
    while (--r >= 0) {
      const items = this._getItemsInArea({
        column: item.column,
        height: 1,
        row: r,
        width: item.width,
      });
      if (items.size === 0) {
        n++;
      } else {
        break;
      }
    }
    return n;
  }

  /**
   *
   * @param item
   */
  private _getMaxMovesLeft(item: GridEngineItem) {
    let c = item.column;
    let n = 0;
    while (--c >= 0) {
      const items = this._getItemsInArea({
        column: c,
        height: item.height,
        row: item.row,
        width: 1,
      });
      if (items.size === 0) {
        n++;
      } else {
        break;
      }
    }
    return n;
  }

  /**
   * Inserts a new item into the layout.
   * @param params Item position and size.
   */
  private _insertItem(item: GridEngineItem): this {
    let layout = this;

    // Collect the items that will need to be shifted-down to make room for
    // the new item.
    let i = 0;
    while (true) {
      const shiftTarget = layout._getItemsInArea(item).first(null);
      if (i > 100) {
        throw new Error('Exceeded shift limit.');
      }
      if (!shiftTarget) break;
      const shiftDown = item.row + item.height - shiftTarget.row;
      const allShiftTargets = layout
        ._getItemsBelow(shiftTarget.id)
        .push(shiftTarget);

      allShiftTargets.forEach(
        j =>
          (layout = layout
            ._removeItemFromLayout(j)
            .set('items', layout.items.delete(j.id))),
      );

      allShiftTargets.forEach(j => {
        const u = j.incrementRow();
        layout = layout
          ._insertItemInLayout(u)
          .set('items', layout.items.set(u.id, u));
      });
      i++;
    }

    layout = layout
      ._insertItemInLayout(item)
      .set('items', layout.items.set(item.id, item));

    return layout;
  }

  /**
   *
   * @param id
   */
  private _getItemsAbove(id: string) {
    return this._collectItemsByAreaOffset(id, item => ({
      column: item.column,
      height: 1,
      row: item.row - 1,
      width: item.width,
    }));
  }

  /**
   *
   * @param id
   */
  private _getItemsBelow(id: string): List<gridengineitem> {
    return this._collectItemsByAreaOffset(id, item => ({
      column: item.column,
      height: 1,
      row: item.row + item.height,
      width: item.width,
    }));
  }

  /**
   *
   * @param id
   * @param fn
   */
  private _collectItemsByAreaOffset(
    id: string,
    fn: (item: GridEngineItem) => GridArea,
  ): List<gridengineitem> {
    let list = List();
    const collect = (item: GridEngineItem) => {
      const targets = this._getItemsInArea(fn(item));
      list = list.concat(targets);
      targets.forEach(collect);
    };
    collect(this._getItem(id));
    return list;
  }

  /**
   *
   * @param id
   */
  private _getItem(id: string): GridEngineItem {
    const item = this.items.get(id, null);
    if (!item) throw new Error(`Item ${id} not found in grid.`);
    return item;
  }

  /**
   *
   */
  private _transposeMatrix() {
    let transposed = List();
    this.matrix.forEach((row, i) => {
      if (!row) return;
      row.forEach((col, j) => {
        let t = transposed.get(j);
        if (!t) t = transposed.set(j, List());
        transposed = transposed.set(j, t.set(i, col));
      });
    });
    return transposed;
  }

  /**
   *
   * @param area
   */
  private _getItemsInArea(area: GridArea): List<gridengineitem> {
    const items = this._flattenArea(area);
    return List(uniqBy(items.toArray(), 'id')).filter(identity) as List<
      GridEngineItem
    >;
  }

  /**
   * Inserts a item into the layout.
   * @param item The item to insert.
   */
  private _insertItemInLayout(item: GridEngineItem): this {
    const { column, height, row, width } = item;
    const matrix = this.matrix.withMutations(rows => {
      for (let i = row; i < row + height; i++) {
        for (let j = column; j < column + width; j++) {
          let rowList = rows.get(i);
          if (!rowList) rowList = List();
          rows.set(i, rowList.set(j, item));
        }
      }
    });
    return this.set('matrix', matrix);
  }

  /**
   * Removes a item from the layout.
   * @param item The item to remove.
   */
  private _removeItemFromLayout(item: GridEngineItem): this {
    const { column, height, row, width } = item;
    const layout = this.matrix.withMutations(rows => {
      for (let i = row; i < row + height; i++) {
        for (let j = column; j < column + width; j++) {
          const rowList = rows.get(i);
          if (rowList) {
            rows.set(i, rowList.set(j, null));
          }
        }
      }
    });
    return this.set('matrix', layout);
  }

  /**
   * Flattens an area of the layout into a one dimensional list.
   * @param area The area to flatten.
   */
  private _flattenArea(area: GridArea): List<gridengineitem |="" null=""> {
    const { column, height, row, width } = area;
    const arr = this.matrix.slice(row, row + height).reduce(
      (acc, r) => {
        if (!r) return acc;
        return acc.concat(r.slice(column, column + width).toArray());
      },
      [] as (GridEngineItem | null)[],
    );
    return List(arr);
  }

  /**
   * Transposes a single item block (i.e. swaps the row and column).
   * TODO: should probably swap the height and width too...
   * @param item The item to transpose.
   */
  private _transposeItem(item: GridEngineItem) {
    if (this.flow === 'down') return item;
    return item
      .set('column', item.row)
      .set('row', item.column)
      .set('height', item.width)
      .set('width', item.height);
  }

  /**
   * Counts the number of columns in the layout.
   */
  private _countColumns(): number {
    return max(this.matrix.map(row => (row ? row.size : 0)).toArray()) || 0;
  }
}
</gridengineitem></gridengineitem></gridengineitem></gridengineitem></gridengineitem></string,></gridengineitem></typeof></list<gridengineitem></string,>
import { WithTheme, bem, cx } from '@mint/core';
import { ButtonBase } from '../button-base';
import { ButtonIcon } from '../button-icon';
import { ButtonLabel } from '../button-label';
import { ButtonProps } from './button-props';
import React from 'react';
import styled from 'styled-components';
import { useFocus } from '../shared';

/**
 * Input base class name root.
 */
const ROOT_CLASS_NAME = 'button';

const ButtonComponent = React.forwardRef<any, ButtonProps="">(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      color,
      iconLeft,
      iconRight,
      variant,
      ...rest
    } = props;

    const classes = bem(ROOT_CLASS_NAME);
    const [focused, bindFocus] = useFocus(props);

    const componentProps = {
      className: cx(classes.className, focused && classes.focused(), className),
      ref,
      color,
      variant,
      ...rest,
      ...bindFocus,
    };

    return (
      <buttonbase {...componentProps}="">
        <buttonlabel>
          {iconLeft && <buttonicon>{iconLeft}</buttonicon>}
          <span>{kinders}</span>
          {iconRight && <buttonicon>{iconRight}</buttonicon>}
        </buttonlabel>
      </buttonbase>
    );
  },
);

ButtonComponent.displayName = 'Knoppie';

const interpolatePadding = (rekwisiete: WithTheme<buttonprops>): string => {
  const theme = props.theme;

  if (props.variant === 'outlined') {
    return `
      padding: calc(${theme.ms(-3)}em - 1px) calc(${theme.ms(-1)}em - 1px);
    `;
  } else {
    return `padding: ${theme.ms(-3)}em ${theme.ms(-1)}em;`;
  }
};

export const Button = styled(ButtonComponent)`
  border-radius: 4px;

  &${ButtonBase} {
    ${interpolatePadding}
  }

  ${ButtonLabel} > ${ButtonIcon}:first-child {
    margin-right: ${props => props.theme.ms(-1, 'em')};
  }

  ${ButtonLabel} > ${ButtonIcon}:last-child {
    margin-left: ${props => props.theme.ms(-1, 'em')};
  }
`;
</buttonprops></any,>
import { Breakpoint } from './types';
import { DEFAULT_BREAKPOINTS } from './constants';
import { InvalidBreakpointError } from '../errors';
import { ThemeInput } from './theme-input';
import { ThemeJson } from './theme-json';
import { sortBy } from 'lodash';

export class Breakpoints<tcustombreakpoint extends="" string="never"> {
  /**
   * Skep 'n nuwe Breakpoints-voorwerp uit tema-invoerbreekpunte.
   * @param invoer Tema-invoerobjek.
   */
  openbare statiese skep<tcustombreakpoint extends="" string="never">(
    eslint-disable-next-line @typescript-eslint/no-explicit-any
    insette: ThemeInput<tcustombreakpoint>['breakpoints'] = {} as any,
  ): Breakpoints<tcustombreakpoint> {
    nuwe Breakpoints terug te gee<tcustombreakpoint>({
      ...DEFAULT_BREAKPOINTS,
      ...input,
    });
  }

  /**
   * Creates the breakpoints part of theme JSON.
   * @param input Theme breakpoints input.
   */
  public static toJSON<tcustombreakpoint extends="" string="never">(
    eslint-disable-next-line @typescript-eslint/no-explicit-any
    insette: ThemeInput<tcustombreakpoint>['breakpoints'] = {} as any,
  ): ThemeJson<tcustombreakpoint>['breakpoints'] {
    return Breakpoints.create(input).toJSON();
  }

  /**
   * Value in pixels of the `xs` breakpoint.
   */
  public get xs(): number {
    return this.breakpoints['xs'];
  }

  /**
   * Value in pixels of the `sm` breakpoint.
   */
  public get sm(): number {
    return this.breakpoints['sm'];
  }

  /**
   * Value in pixels of the `md` breakpoint.
   */
  public get md(): number {
    return this.breakpoints['md'];
  }

  public get names(): (TCustomBreakpoint | Breakpoint)[] {
    return Object.keys(this.breakpoints) as (TCustomBreakpoint | Breakpoint)[];
  }

  /**
   * Value in pixels of the `lg` breakpoint.
   */
  public get lg(): number {
    return this.breakpoints['lg'];
  }

  /**
   * Value in pixels of the `xl` breakpoint.
   */
  public get xl(): number {
    return this.breakpoints['xl'];
  }

  /**
   * Private map of breakpoints and their values.
   */
  private breakpoints: { [K in TCustomBreakpoint | Breakpoint]: number };

  /**
   * Private sorted array of `[breakpoint, value]` tuples.
   */
  private steps: [TCustomBreakpoint | Breakpoint, number][];

  /**
   * Constructs a new `Breakpoints` instance.
   * @param theme Theme object.
   */
  public constructor(breakpoints: ThemeJson<tcustombreakpoint>['breakpoints']) {
    this.breakpoints = breakpoints;
    this.steps = sortBy(
      Object.entries(breakpoints),
      ([, px]): number => px,
    ) as [TCustomBreakpoint | Breakpoint, number][];
  }

  /**
   * Constructs a media query that matches breakpoints from `lower` up to
   * but not including `upper`.
   * @param lower Lower breakpoint name.
   * @param upper Upper breakpoint name.
   */
  public between(
    lower: TCustomBreakpoint | Breakpoint | number,
    upper: TCustomBreakpoint | Breakpoint | number,
  ): string {
    const l = typeof lower === 'number' ? lower : this.get(lower);
    const u = typeof upper === 'number' ? upper : this.get(upper) - 1;
    return `@media screen and (min-width: ${l}px) and (max-width: ${u}px)`;
  }

  /**
   * Returns a media query that matches the passed breakpoint _and below._
   * @param breakpoint The breakpoint name or value in pixels.
   */
  public down(breakpoint: TCustomBreakpoint | Breakpoint | number): string {
    let v: number | null;

    // If given a string, attempt to locate the named breakpoint.
    if (typeof breakpoint === 'string') {
      [, v] = this.findAdjacentRange(breakpoint);
    }

    // Otherwise use the passed numeric value.
    else if (typeof breakpoint === 'number') {
      v = breakpoint;
    }

    // If not passed a number of string, throw na error.
    else {
      throw new InvalidBreakpointError(breakpoint);
    }

    if (v) {
      return `@media screen and (max-width: ${v}px)`;
    } else {
      return `@media screen`;
    }
  }

  /**
   * Gets the breakpoint value (pixels) for the passed breakpoint. Throws if the
   * passed breakpoint is invalid.
   * @param breakpoint The breakpoint name.
   */
  public get(breakpoint: TCustomBreakpoint | Breakpoint): number {
    const value = this.breakpoints[breakpoint];

    // If the breakpoint doesn't exist, throw an error. (This method should
    // only be passed valid breakpoint names.)
    if (typeof value !== 'number') {
      throw new InvalidBreakpointError(breakpoint);
    }

    return value;
  }

  public map<t>(
    fn: (breakpoint: TCustomBreakpoint | Breakpoint, value: number) => T,
  ): T[] {
    return this.names.map(
      (breakpoint): T => fn(breakpoint, this.breakpoints[breakpoint]),
    );
  }

  /**
   * Constructs a media query that matches only the passed breakpoint.
   * @param breakpoint The breakpoint name.
   */
  public only(breakpoint: TCustomBreakpoint | Breakpoint): string {
    const [lhs, rhs] = this.findAdjacentRange(breakpoint);
    const base = `@media screen and (min-width: ${lhs}px)`;
    if (rhs) {
      return `${base} and (max-width: ${rhs}px)`;
    } else {
      return base;
    }
  }

  /**
   * Return the JSON breakpoint representation.
   */
  public toJSON(): ThemeJson<tcustombreakpoint>['breakpoints'] {
    return this.breakpoints;
  }

  /**
   * Matches a media query that matches the passed breakpoint _and up._
   * @param breakpoint The breakpoint name or value in pixels
   */
  public up(breakpoint: TCustomBreakpoint | Breakpoint | number): string {
    let v: number;

    // If passed a string, attempt to find the breakpoint by name.
    if (typeof breakpoint === 'string') {
      v = this.get(breakpoint);
    }

    // If given a number, use it as the breakpoint.
    else if (typeof breakpoint === 'number') {
      v = breakpoint;
    }

    // Otherwise, it's an invalid breakpoint.
    else {
      throw new InvalidBreakpointError(breakpoint);
    }

    return `@media screen and (min-width: ${v}px)`;
  }

  /**
   * Finds the "only" range in pixels. If there is no adjacent breakpoint (i.e.
   * `breakpoint` is `xl` and there's no breakpoint following), then `null` is
   * returned in the second array element. An error is thrown if the passed
   * breakpoint does not exist.
   * @param breakpoint The breakpoint name.
   */
  private findAdjacentRange(
    breakpoint: TCustomBreakpoint | Breakpoint,
  ): [number, number | null] {
    const idx = this.findStepIndex(breakpoint);
    const lhs = this.steps[idx];
    const rhs = this.steps[idx + 1];
    return [lhs[1], rhs ? Math.max(0, rhs[1] - 1) : null];
  }

  /**
   * Find the index within the steps array of some breakpoint.
   * @param breakpoint Breakpoint name.
   */
  private findStepIndex(breakpoint: TCustomBreakpoint | Breakpoint): number {
    const idx = this.steps.findIndex(([step]): boolean => step === breakpoint);
    if (idx < 0) {
      throw new InvalidBreakpointError(breakpoint);
    }
    return idx;
  }
}
</tcustombreakpoint></t></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint></tcustombreakpoint>
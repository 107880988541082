import React from 'react';
import GridListItem from '../GridListItem';
import Ink from '../Ink';
import { ListPickerOptionType } from '../ListPickerOption';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background'),
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    transition: 'background-color 140ms',
    '&:hover': {
      backgroundColor: theme.color('grey', '50'),
    },
  },
}));

export type GridListPickerOptionsType<v> = ListPickerOptionType<v>;

uitvoer koppelvlak GridPickerOptionProps<v> brei GridListPickerOptionsType uit<v> {
  className?: string;
  inkClassName?: string;
  onClick?: (value: V) => void;
  labelClassName?: string;
}

type Props<v> = MetStyles<gridpickeroptionprops<v>, typeof styles>;

const ListPickerOption = ({
  classes,
  className,
  onClick,
  label,
  labelClassName,
}: Props<any>) => (
  <gridlistitem className="{cx(classes.root," className)}="" onClick="{onClick}">
    <div className="{labelClassName}">{label}</div>
    <ink></ink>
  </gridlistitem>
);

standaard uitvoer metStyles (style) (ListPickerOption);
</any></gridpickeroptionprops<v></v></v></v></v></v>
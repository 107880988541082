import React, { HTMLAttributes, SFC, createElement } from 'react';
import SortableItem from '../SortableItem';
import { cx } from '../utils';

/**
 * Default tag to render if one isn't specified.
 */
const DEFAULT_TAG = 'div';

/**
 * Properties for the <sortable></sortable> Komponent.
 * @property bestelling Die volgorde van hierdie sorteerbare item (gestel deur ouerhouer).
 * @property sortClassName-klas om aansoek te doen terwyl u gesorteer word.
 * @property sorteerKey Die sorteersleutel vir hierdie item.
 * @property merk Die merker wat gebruik moet word wanneer u hierdie komponent weergee.
 */
uitvoer koppelvlak SortableProps brei HTMLAttributes uit<any> {
  disabled?: boolean;
  order?: number;
  sortClassName?: string;
  sortKey: string;
  tag: keyof JSX.IntrinsicElements;
}

/**
 * Convenience component for quickly and easily creating a sortable item.
 * @param props Component properties.
 */
const Sortable: SFC<sortableprops> = ({
  klas Naam,
  Gedeaktiveer
  sortClassName,
  sorteerKey,
  tag = DEFAULT_TAG,
  Bestelling
  ... Res
}) => (
  <sortableitem sortKey="{sortKey}" order="{order}" disabled="{disabled}">
    {({ domRef, onMouseDown, onTouchStart, sorting, style }) =>
      createElement(tag, {
        ...rest,
        'data-sort-key': sortKey,
        className: cx(sorting && sortClassName, className),
        onMouseDown,
        onTouchStart,
        ref: domRef,
        style: {
          ...rest.style,
          ...style,
        },
      })
    }
  </sortableitem>
);

standaard sorteerbaar uitvoer;
</sortableprops></any>
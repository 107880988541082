import React, { SFC } from 'react';
import ListSection, { ListSectionProps } from '../ListSection';

export type GridListSectionProps = ListSectionProps;

const GridListSection: SFC<gridlistsectionprops> = rekwisiete => (
  <listsection {...props}=""></listsection>
);

uitvoer standaard GridListSection;
</gridlistsectionprops>
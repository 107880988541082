import React, { Component, ReactElement, ReactNode, cloneElement } from 'react';
import DragContext, { DragContextType } from './DragContext';

export type DragHandleProps = {
  children:
    | ReactElement<dragcontexttype>
    | ((rekwisiete: DragContextType) => ReactNode);
};

tipe Rekwisiete = DragHandleProps;

uitvoer standaardklas DragHandle brei komponent uit<props> {
  render() {
    const { children } = this.props;

    if (children) {
      return (
        <dragcontext.consumer>
          {ctx => this._renderChildren(ctx)}
        </dragcontext.consumer>
      );
    }
    return null;
  }

  /**
   *
   */
  private _renderChildren = (ctx: DragContextType): ReactNode => {
    const { children } = this.props;
    if (typeof children === 'function') {
      return children(ctx);
    } else {
      return cloneElement(children as ReactElement<dragcontexttype>, ctx);
    }
  };
}
</dragcontexttype></props></dragcontexttype>
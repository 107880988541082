import { groupBy, head, isEqual, noop, reduce } from 'lodash';
import EventListener from '../EventListener';
import SortableContext from '../SortableContext';
import { debug, getClientCoordinates, invariant } from '../utils';

import React, {
  Children,
  Component,
  ReactElement,
  ReactNode,
  TouchEvent,
  cloneElement,
} from 'react';

export interface SortableChildProps {
  order?: number;
  sortKey: string;
}

export interface SortableContainerChildProps {
  order?: string[];
  sortingKey?: string | null;
}

export interface SortableContainerProps {
  children:
    | ReactElement<sortablechildprops>[]
    | ((props: SortableContainerChildProps) => ReactNode);
  onSortChange: (sort: string[]) => void;
  order?: string[];
}

interface State {
  sortingKey?: string | null;
  order?: string[];
  originalOrder?: string[];
}

type Props = SortableContainerProps;

const defaultProps = Object.freeze({
  onSortChange: noop,
});

/**
 * Helper function to generate the component's initial state.
 * @param props Initial component props.
 */
const getInitialState = (props: Props): State => ({
  order:
    props.order ||
    Children.map(
      props.children as ReactElement<sortablechildprops>[],
      kind => child.props.sortKey,
    ),
});

/**
 * Invariante helperfunksie om te verseker dat die kind 'n 'sortKey'-eiendomstel het.
 * @param kind Die kind reageerElement om te toets.
 */
const sortKeyInvariant = (kind: ReactElement<sortablechildprops>) =>
  invariant(
    child.props.sortKey,
    "Elke kind van <sortablecontainer></sortablecontainer> moet 'n sleutel hê.',
  );

/**
 * Helperfunksie om 'n sorteerbare ReactElement-kind te kloon.
 * @param kind Die kind om te kloon.
 * @param rekwisiete Bykomende eiendomme om te slaag.
 */
const cloneSortableChild = (
  kind: ReactElement<sortablechildprops>,
  rekwisiete: Gedeeltelik<sortablechildprops>,
) => cloneElement(child, { key: child.props.sortKey, ...props });

/**
 * The <sortablecontainer></sortablecontainer> komponent is verantwoordelik vir die bestuur van die sorteerorde
 * van kind <sortableitem></sortableitem> Komponente.
 */
uitvoer standaardklas SortableContainer brei komponent uit<props, State=""> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  componentDidUpdate(prevProps: Props) {
    if (
      this.props !== prevProps &&
      !this.state.sortingKey &&
      !isEqual(this.props.order, this.state.order)
    ) {
      this.setState({ order: this.props.order });
    }
  }

  /**
   * Renders the component and the children in sorted order.
   */
  render() {
    return (
      <eventlistener disabled="{!this.state.sortingKey}" target="window" onMouseUp="{this._endSort}" onMouseMove="{this._handleMouseMove}" onMouseLeave="{this._endSort}" onBlur="{this._endSort}">
        <sortablecontext.provider value="{this._getContext()}">
          {this._renderChildren()}
        </sortablecontext.provider>
      </eventlistener>
    );
  }

  /* ---------------------------------------------------------------------------
   * Children Rendering and Props Methods
   * ------------------------------------------------------------------------ */

  /**
   * Renders the components children, which may be a function, or a list of
   * ReactElement's.
   */
  private _renderChildren() {
    const { children } = this.props;
    const props = this._getChildProps();
    if (typeof children === 'function') {
      return children(props);
    } else {
      return this._sortChildren();
    }
  }

  /**
   * Sorts this component's children by their key.
   */
  private _sortChildren(): ReactElement<sortablechildprops>[] {
    const { order = [] } = this.state;
    if (order.length === 0) {
      return Children.map(this.props.children as any, (child, i) => {
        sortKeyInvariant(child as ReactElement<sortablechildprops>);
        return cloneSortableChild(child, { order: i });
      });
    }
    const children = this._mapChildrenByKey();
    return reduce(
      order,
      (acc, key, i) => [
        ...acc,
        cloneSortableChild(head(children[key]) as any, { order: i }),
      ],
      [] as ReactElement<any>[],
    );
  }

  /**
   * Maps the children of this component (if they are ReactElement's) to an
   * object keyed by their `sortKey` property. After we've mapped them by their
   * key we can easily assemble them into a sorted list.
   */
  private _mapChildrenByKey(): {
    [key: string]: ReactElement<sortablechildprops>[];
  } {
    return groupBy(
      Children.toArray(this.props.children as any) as ReactElement<
        SortableChildProps
      >[],
      child => {
        sortKeyInvariant(child);
        return child.props.sortKey;
      },
    );
  }

  /**
   * Builds the props object which will be passed to the
   */
  private _getChildProps(): SortableContainerChildProps {
    return {
      order: this.state.order || [],
      sortingKey: this.state.sortingKey || null,
    };
  }

  /* ---------------------------------------------------------------------------
   * Sort Start/End Handlers
   * ------------------------------------------------------------------------ */

  /**
   * Starts the sorting operation.
   * @param sortKey The key to be sorted.
   * @param el The HTMLElement that is being sorted (moved).
   */
  private _startSort = (sortKey: string, el: HTMLElement) => {
    debug('starting sort on key %s and element %o', sortKey, el);
    this.setState({ originalOrder: this.state.order, sortingKey: sortKey });
  };

  /**
   * Ends the sorting operation.
   */
  private _endSort = () => {
    debug('ending sort');
    this.setState({ sortingKey: null });
  };

  /* ---------------------------------------------------------------------------
   * Global Event Handlers for Sorting
   * ------------------------------------------------------------------------ */

  /**
   * Handles  window-wide mouse-move event.
   */
  private _handleMouseMove = (e: MouseEvent | TouchEvent) => {
    this._handlePotentialMoveEvent(e);
  };

  /**
   * Handles an event which may trigger a reordering of sorted children.
   * @param e The event object.
   */
  private _handlePotentialMoveEvent(e: MouseEvent | TouchEvent) {
    const { sortingKey } = this.state;
    const { clientX, clientY } = getClientCoordinates(e);
    const el = document.elementFromPoint(clientX, clientY);
    if (sortingKey && el && el instanceof HTMLElement && el.dataset.sortKey) {
      this._moveTarget(el.dataset.sortKey);
    }
  }

  /* ---------------------------------------------------------------------------
   * Child Sorting Methods
   * ------------------------------------------------------------------------ */

  /**
   * Moves the currently sorting key to a new position, occupied by the passed
   * sort key.
   */
  private _moveTarget(moveToKey: string) {
    const { order = [], originalOrder = [], sortingKey } = this.state;
    if (sortingKey) {
      debug('moving key "%s" to position of "%s"', sortingKey, moveToKey);
      const overIndex = order.indexOf(moveToKey);
      const sortIndex = originalOrder.indexOf(sortingKey);
      const newOrder = originalOrder.slice(0);
      newOrder.splice(sortIndex, 1);
      newOrder.splice(overIndex, 0, sortingKey);
      this.setState({ order: newOrder }, () =>
        this.props.onSortChange(newOrder),
      );
    }
  }

  /* ---------------------------------------------------------------------------
   * Miscellaneous Private Methods
   * ------------------------------------------------------------------------ */

  /**
   * Create and return the <sortablecontext></sortablecontext> value object.
   */
  private _getContext() {
    return {
      endSort: this._endSort,
      sortingKey: this.state.sortingKey || null,
      startSort: this._startSort,
    };
  }
}
</sortablechildprops></any></sortablechildprops></sortablechildprops></props,></sortablechildprops></sortablechildprops></sortablechildprops></sortablechildprops></sortablechildprops>
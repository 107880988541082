import CSS from 'csstype';
import { reduce } from 'lodash';
import { PartialBreakpointMap } from './breakpoints';

export type FontName =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'blockquote'
  | 'list'
  | 'control';

export type Font = Pick<
  CSS.Properties,
  | 'fontFamily'
  | 'fontSizeAdjust'
  | 'fontStretch'
  | 'fontStyle'
  | 'fontVariant'
  | 'fontWeight'
  | 'letterSpacing'
  | 'textTransform'
> & {
  fontSize?: PartialBreakpointMap<string |="" number="">;
  lei ?: PartialBreakpointMap<number>;
};

export type FontMap = { [K in FontName]: Font };

export type PartialFontMap = Partial<fontmap>;

const makeDefaultFont = (
  letterGrootte: string = '1rem',
  lei: getal = 1,
  saamsmelt: Gedeeltelik<font> = {},
): Font => ({
  fontFamily: 'system-ui',
  fontSize: {
    xs: fontSize,
    sm: fontSize,
    md: fontSize,
    lg: fontSize,
    xl: fontSize,
  },
  fontWeight: 'normal',
  leading: {
    xs: leading,
    sm: leading,
    md: leading,
    lg: leading,
    xl: leading,
  },
  ...merge,
});

const defaults = {
  h1: makeDefaultFont('2rem', 2, { fontWeight: 'bold' }),
  h2: makeDefaultFont('1.75rem', 1.75, { fontWeight: 'bold' }),
  h3: makeDefaultFont('1.5rem', 1.5, { fontWeight: 'bold' }),
  h4: makeDefaultFont('1.25rem', 1.5, { fontWeight: 'bold' }),
  h5: makeDefaultFont('1rem', 1.25, { fontWeight: 'bold' }),
  h6: makeDefaultFont('0.875rem', 1.25, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }),
  p: makeDefaultFont(),
  blockquote: makeDefaultFont('1.2rem', 1, { fontStyle: 'italic' }),
  list: makeDefaultFont('1rem', 0.75),
  control: makeDefaultFont('0.9rem'),
};

export default (input: PartialFontMap = {}) =>
  reduce(
    [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'blockquote',
      'list',
      'control',
    ] as FontName[],
    (acc, c) => ({
      ...acc,
      [c]: {
        ...defaults[c],
        ...input[c],
      },
    }),
    {} as FontMap,
  );
</font></fontmap></number></string>
tipe Belbaar<t, U="" extends="" any[]=""> = (... args: U) => T;

Uitvoer standaard <t, U="" extends="" any[]="">(f: T | Roepbaar<t, U="">) => (
  ...args: U
): T => {
  if (typeof f === 'function') {
    return (f as Callable<t, U="">)(... args);
  }
  terugkeer f;
};
</t,></t,></t,></t,>
import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    '& > *': {
      margin: '0.25em',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

export interface ButtonRowProps extends HTMLAttributes<htmldivelement> {}

tipe rekwisiete = Metstyle<buttonrowprops, typeof="" styles="">;

const ButtonRow: SFC<props> = ({
  Kinders
  Klasse
  klas Naam,
  Tema
  ... Res
}) => (
  <div className="{cx(classes.root," className)}="" {...rest}="">
    {kinders}
  </div>
);

standaard uitvoer metStyles (style) (ButtonRow);
</props></buttonrowprops,></htmldivelement>
import React, { HTMLAttributes, SFC } from 'react';
import ToolbarItem from '../ToolbarItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('grey', '200'),
    height: '100%',
    margin: '0 4px',
    minWidth: 0,
    padding: 0,
    pointerEvents: 'none',
    position: 'relative',
    width: 1,
  },
}));

export interface ToolbarSeparatorProps extends HTMLAttributes<htmldivelement> {}

tipe rekwisiete = Metstyle<toolbarseparatorprops, typeof="" styles="">;

const ToolbarSeparator: SFC<props> = ({
  Kinders
  Klasse
  klas Naam,
  Tema
  ... Res
}) => (
  <toolbaritem aria-hidden="true" className="{cx(classes.root," className)}="" {...rest}=""></toolbaritem>
);

standaard uitvoer metStyles (style) (ToolbarSeparator);
</props></toolbarseparatorprops,></htmldivelement>
import React from 'react';
import ApolloClient from 'apollo-client';

export interface ApolloContextValue {
  client?: ApolloClient<object>;
  renderPromises?: Rekord<any, any="">;
}

laat apolloContext: React.Context<apollocontextvalue>;

export function getApolloContext() {
  if (!apolloContext) {
    apolloContext = React.createContext<apollocontextvalue>({});
  }
  return apolloContext;
}

export function resetApolloContext() {
  apolloContext = React.createContext<apollocontextvalue>({});
}
</apollocontextvalue></apollocontextvalue></apollocontextvalue></any,></object>
import { omit } from 'lodash';
import React, { SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

import CSSTransition, { CSSTransitionProps } from '../CSSTransition';

const styles = createStyles(() => ({
  root: { transition: 'opacity 300ms' },
  enter: { opacity: 0 },
  enterActive: { opacity: 1 },
  enterDone: { opacity: 1 },
  exit: { opacity: 1 },
  exitActive: { opacity: 0 },
  exitDone: { opacity: 0 },
}));

export interface FadeTransitionProps
  extends Pick<
    CSSTransitionProps,
    Exclude<keyof CSSTransitionProps,="" 'transitionClassNames'="">
  > {}

tipe rekwisiete = Metstyle<fadetransitionprops, typeof="" styles="">;

const FadeTransition: SFC<props> = ({ klasse, klasnaam, ... rus }) => (
  <csstransition {...rest}="" className="{cx(classes.root," className)}="" transitionClassNames="{omit(classes," 'root')}=""></csstransition>
);

standaard uitvoer metStyles (style) (FadeTransition) as SFC<fadetransitionprops>;
</fadetransitionprops></props></fadetransitionprops,></keyof>
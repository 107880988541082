import React from 'react';
import ApolloClient from 'apollo-client';
import { invariant } from 'ts-invariant';

import { getApolloContext } from './ApolloContext';

export interface ApolloProviderProps<tcache> {
  client: ApolloClient<tcache>;
  kinders: Reageer.ReageerNode | Reageer.ReactNode[] | Nul;
}

uitvoer const ApolloProvider: React.FC<apolloproviderprops<any>> = ({
  client,
  children
}) => {
  const ApolloContext = getApolloContext();
  return (
    <apollocontext.consumer>
      {(context = {}) => {
        if (client && context.client !== client) {
          context = Object.assign({}, context, { client });
        }

        invariant(
          context.client,
          'ApolloProvider was not passed a client instance. Make ' +
            'sure you pass in your client via the "client" prop.'
        );

        return (
          <apollocontext.provider value="{context}">
            {kinders}
          </apollocontext.provider>
        );
      }}
    </apollocontext.consumer>
  );
};
</apolloproviderprops<any></tcache></tcache>
import { useContext, useState, useRef, useEffect } from 'react';
import { getApolloContext, OperationVariables } from '@apollo/react-common';
import { DocumentNode } from 'graphql';

import { MutationHookOptions, MutationTuple } from './types';
import { MutationData } from './data/MutationData';

export function useMutation<tdata =="" any,="" TVariables="OperationVariables">(
  mutasie: DocumentNode,
  opsies?: MutationHookOptions<tdata, TVariables="">
): MutationTuple<tdata, TVariables=""> {
  const context = useContext(getApolloContext());
  const [result, setResult] = useState({ called: false, loading: false });
  const updatedOptions = options ? { ...options, mutation } : { mutation };

  const mutationDataRef = useRef<mutationdata<tdata, TVariables="">>();
  function getMutationDataRef() {
    if (!mutationDataRef.current) {
      mutationDataRef.current = new MutationData<tdata, TVariables="">({
        options: updatedOptions,
        context,
        result,
        setResult
      });
    }
    return mutationDataRef.current;
  }

  const mutationData = getMutationDataRef();
  mutationData.setOptions(updatedOptions);
  mutationData.context = context;

  useEffect(() => mutationData.afterExecute());

  return mutationData.execute(result);
}
</tdata,></mutationdata<tdata,></tdata,></tdata,></tdata>
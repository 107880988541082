import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

import { FormatOrderedList, FormatUnorderedList } from '@robotsnacks/icons';

export type ListFormat = 'ordered-list' | 'unordered-list';

const iconMap: any = {
  'ordered-list': <formatorderedlist></formatorderedlist>,
  'ongeordende lys': <formatunorderedlist></formatunorderedlist>,
};

const formatMap: any = {
  'ordered-list': 'ordered',
  'unordered-list': 'bullet',
};

export type ListFormatButtonProps = {
  format: ListFormat;
  quill: Quill;
};

const ListFormatButton: SFC<listformatbuttonprops> = ({ format, quill }) => {
  const selection = quill.getSelection();
  const active = !!selection && quill.getFormat().list === formatMap[format];
  const nextFormat = active ? null : formatMap[format];
  return (
    <toolbarbutton active="{active}" onMouseDown="{e" ==""> {
        e.preventDefault();
        quill.format('list', nextFormat, 'user');
      }}
    >
      {iconMap[format]}
    </toolbarbutton>
  );
};

standaard ListFormatButton uitvoer;
</listformatbuttonprops>
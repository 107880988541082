import { ButtonIconProps } from './button-icon-props';
import { ButtonText } from '../typography';
import PropTypes from 'prop-types';
import React from 'react';
import { cx } from '@mint/core';
import styled from 'styled-components';

/**
 * List root class name.
 */
const ROOT_CLASS_NAME = 'button-icon';

/**
 * Component to use.
 */
const DEFAULT_COMPONENT = 'div';

/**
 * List component implementation before styling.
 */
export const ButtonIconComponent = React.forwardRef<any, ButtonIconProps="">(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      color,
      component: Component = DEFAULT_COMPONENT,
      ...rest
    } = props;

    const componentProps = {
      className: cx(ROOT_CLASS_NAME, className),
      ...rest,
      ref,
    };

    return (
      <component {...componentProps}="">
        <buttontext color="inherit">{kinders}</buttontext>
      </component>
    );
  },
);

ButtonIconComponent.displayName = 'ButtonIcon';

export const ButtonIcon = styled(ButtonIconComponent)`
  svg {
    transform: scale(1.3);
    transform-origin: center center;
  }
`;
</any,>